import moment from "moment";
import { useEffect, useState } from "react";
import segmentFlights from "../../../../utils/flight/segmentFlights.js";
import Icon from "../../../../components/HOC/Icon";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export const FlightDetails = ({order}) => {
    const orderData = order?.booking?.flightBooking?.at(0);
    
    function getAPNR(ref) {
      try {
        return orderData?.travelers?.at(0)?.pnrList?.find(pnr => pnr?.segmentRef === ref)?.airlinePnr
      } catch(ex) {}
      return ''
    }
    
    const routes = orderData?.flights?.map((flight,i) => (
      {
        airline: { name: flight?.airlineName, image: flight?.airlineImage, pnr: getAPNR(flight?.segmentRef) },
        departure: {
          city: flight?.departureLocationName,
          code: flight?.departureLocation,
          date: moment(flight?.departureDate).format('ddd MMM DD'),
          time: flight?.departureTime,
          airport: flight?.departureAirport,
          terminal: flight?.departureTerminal,
        },
        arrival: {
          city: flight?.arrivalLocationName,
          code: flight?.arrivalLocation,
          date: moment(flight?.arrivalDate).format('ddd MMM DD'),//"Wed Aug 07",
          time: flight?.arrivalTime,//"11:50am",
          airport: flight?.arrivalAirport,
          terminal: flight?.arrivalTerminal,
        },
        length: flight?.duration,
        type: flight?.cabinClass,
        class: flight?.bookingClass,
        seat: (flight?.seats||[])?.join(', '),
        flightNumber: flight?.flightNumber,
        detailsOpen: false,
        segment: flight?.segment,
        segmentRef: flight?.segmentRef,
      }
    ))
  
    console.log(orderData,routes)
  
    const [details, setDetails] = useState(segmentFlights(routes));
  
    useEffect(() => {
      setDetails(segmentFlights(routes))
    },[order])
  
    // console.log(details,order)
    
    return (
      <div className="w-full flex flex-col mt-6">
        <span className="font-bold text-xl mb-3">Flight details</span>
        {details?.map((flight,i) => (
          <div key={i} className="mb-4">
                <div className="lg:items-center gap-2 flex-col lg:flex-row flex justify-between p-4 bg-white shadow-md rounded-lg">
                  <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
                    <span className="min-w-[24px] h-6 rounded-md text-white font-bold items-center justify-center hidden sm:flex text-sm bg-primary1">
                      {i + 1}
                    </span>
                    <img
                      src={flight?.at(0)?.airline?.image}
                      alt=""
                      className="w-6 object-contain"
                    />
                    <div className="flex flex-col gap-2">
                      <span className="text-sm lg:text-base font-bold">
                        {flight?.at(0)?.departure?.city} <span className="text-theme1 hidden sm:inline-block">{flight?.at(0)?.departure?.code}</span> —{" "}
                        {flight?.at(-1)?.arrival?.city} <span className="text-theme1 hidden sm:inline-block">{flight?.at(-1)?.arrival?.code}</span>
                      </span>
                      {/* <div className="flex items-center gap-2">
                        <span className="text-sm lg:text-base">
                          {flight?.at(0)?.airline?.name}
                        </span>
                      </div> */}
                      <div className="flex flex-col sm:flex-row gap-x-6 gap-y-1 items-start text-sm lg:text-base">
                        <div className="flex gap-2">
                          <span className="font-bold">{flight?.at(0)?.departure?.time}</span>
                          <span>{flight?.at(0)?.departure?.date}</span>
                        </div>
                        <div className="flex gap-2">
                          <span className="font-bold">{flight?.at(-1)?.arrival?.time}</span>
                          <span>{flight?.at(-1)?.arrival?.date}</span>
                        </div>
                        <div className="flex items-center gap-1 ">
                          <Icon icon='mdi-light:clock' className='!w-4 !h-4' />
                          <span className="text-primary1">{flight?.at(0)?.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 items-start justify-end text-sm lg:text-base">
                    <div
                      className="flex items-center gap-1 cursor-pointer"
                      onClick={() => {
                        setDetails(details?.map((flights,idx) => 
                          idx === i ? flights?.map(flight => ({ ...flight, detailsOpen: !flight?.detailsOpen })) : flights
                        ))
                        // setDetails(() => {
                        //   const temp = [...details]
                        //   temp[i] = temp[i]?.map((p, idx) =>
                        //     idx === i ? { ...p, detailsOpen: !p.detailsOpen } : p
                        //   )
                        //   return temp;
                        // });
                      }}
                    >
                      {order?.directionsToChange?.includes(flight?.at(0)?.segmentRef) ? 
                        <span className="error text-sm mx-2">Change</span>
                      :''}      
                      <span className="text-sm lg:text-base text-[#818083] ">
                        Details
                      </span>
                      {flight?.at(0)?.detailsOpen ? (
                        <KeyboardArrowUp color="#818083" />
                      ) : (
                        <KeyboardArrowDown color="#818083" />
                      )}
                    </div>
                  </div>
                </div>
            {<div className="">
              {flight?.map((route, index) => (
              <div className="flex w-full flex-col" key={index}>
                {route.detailsOpen && (
                  <div className="w-full px-2 lg:px-6 bg-theme1/5 border flex flex-col">
                    <div className="flex gap-4 hx-32 lg:h-full">
                      {/* <div className=" flex flex-col items-center h-32 lg:h-full justify-between py-4 lg:py-1">
                        <div className="w-3 h-3 rounded-full bg-primary1"></div>
                        <div className="w-1 h-full flex flex-1 py-4 bg-gray-200"></div>
                        <div className="w-3 h-3 rounded-full bg-primary1"></div>
                      </div> */}
                      <div className="flex flex-col flex-1 h-full justify-between p-4 rounded-md">
                        <div className="flex flex-wrap gap-4 mb-4">
                          <div className="flex items-center gap-2">
                            <div className="text-theme1" >
                              {route?.flightNumber}
                            </div>
                            <hr className="w-[10px] border-primary/50" />
                          </div>
                          <div className="font-bold">{route?.airline?.name}</div>
                          <div>{route?.type}<span className="px-2 rounded-md mx-1 bg-primary/10">{route?.class}</span></div>
                          <div className="flex items-center gap-1"><Icon icon='mdi-light:clock' /> {route?.length}</div>
                          <div className="font-bold">Operated by {route?.airline?.name}</div>
                          <div className="flex gap-3 items-center">-
                            <Tooltip title={'Airline PNR'}>
                              <label className="uppercase font-bold px-1 bg-primary/10 rounded-md">{route?.airline?.pnr}</label>
                            </Tooltip>
                          </div>
                        </div>
                        <span className="flex-wrap text-sm lg:text-base text-gray-500 mb-4 flex gap-4">
                          <span className="mr-2 w-[60px] text-black">
                            {route.departure.time}
                          </span>
                          <span className="mr-2 w-[100px]">
                            {route.departure.date}
                          </span>
                          <div className="flex-1">
                            <span className="pr-2 text-theme1">{route.departure?.code}</span>
                            {route.departure.airport}
                          </div>
                        </span>
                        <span className="flex-wrap text-sm lg:text-base text-gray-500 mb-4 flex gap-4">
                          <span className="mr-2 w-[60px] text-black">
                            {route.arrival.time}
                          </span>
                          <span className="mr-2 w-[100px]">
                            {route.arrival.date}
                          </span>
                          <div className="flex-1">
                            <span className="pr-2 text-theme1">{route.arrival?.code}</span>
                            {route.arrival.airport}
                          </div>
                        </span>
                        {/* <div className="text-xs lg:text-sm items-center flex gap-1 text-gray-500">
                          <img
                            src="/IconClock.svg"
                            alt=""
                            className="w-3 object-contain"
                          />
                          <span>{route.length}</span>
                        </div> */}
                        {/* <span className=" text-sm lg:text-base text-gray-500 mt-4">
                          <strong className="text-black mr-2">
                            {route.arrival.date}
                          </strong>
                          Arriving at {route.arrival.airport}
                        </span> */}
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap gap-4 lg:gap-10 text-gray-500 text-sm lg:text-base font-semibold">
                      <span>{route?.type}</span>
                      <span>{route?.class}</span>
                      <span>{route?.seat}</span>
                    </div> */}
                  </div>
                )}
              </div>
            )
              )}
            </div>}
          </div>
        ))}
      </div>
    );
  };
  