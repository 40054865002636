import React, { useCallback, useEffect, useState } from "react";
import Button1 from "../../components/form/Button1";
import { FileDownloadOutlined } from "@mui/icons-material";
import CustomTable from "../../components/Table/CustomTable";
import { alertType } from "../../data/constants";
import Modal1 from "../../components/DIsplay/Modal/Modal1";
import TextInput from "../../components/form/TextInput";
import FilterCalendar from "../../components/form/FilterCalendar";
import getWallet from "../../controllers/settings/wallet/getWallet";
import { useSnackbar } from "notistack";
import setLowBalanceThreshold from "../../controllers/settings/wallet/setLowBalanceThreshold";
import getWalletTransactions from "../../controllers/settings/wallet/getWalletTransactions";
import moment from "moment";
import PaystackButton from "../../components/Paystack/PaystackButton";
import { generateRef } from "../../utils/generateRef";
import { useSelector } from "react-redux";
import topUpWalletUsingModal from "../../controllers/settings/wallet/topUpwalletUsingModal";
import { formatNumber } from "../../features/utils/formatNumber";
import { getTestLevel } from "../../utils/testLevel";
import { Tooltip } from "@mui/material";
import CreateVirtualAccountForm from "../../components/Settings/CreateVirtualAccountForm.js";
import Icon from "../../components/HOC/Icon";
import getVirtualAccounts from "../../controllers/virtualAccount/getVirtualAccounts";
import { getType } from "@reduxjs/toolkit";
import getReasonName from "../../utils/settings/getReasonName";
import { formatMoney } from "../../features/utils/formatMoney";
import FetchUsersInput from "../../components/form/FetchUsersInput.js";
import getAdminTeamMembers from "../../controllers/settings/adminTeam/getTeamMembers.js";


export default function BalanceSetting() {
  const [agents,setAgents] = useState([])
  const [loadings,setLoadings] = useState({agent: false})
  const [selectedAgent,setSelectedAgent] = useState();

  useEffect(() => {
    loadAgents();
  },[])

  async function loadAgents() {
    setLoadings({...loadings,agent: true})
    const res = await getAdminTeamMembers();
    setLoadings({...loadings,agent: false})
    if(res.return) {
      setAgents(res?.data?.data || [])
    }
  }
  
  const columns = [
    // { field: "bookingId", headerName: "Booking ID", flex: 1,
    //   renderCell: (params) => {
    //     const bookingId = params.row?.bookingId
    //     return (
    //       <div>
    //         {bookingId || '-'}
    //       </div>
    //     )
    //   }
    // },
    { field: "orderId", headerName: "Order Id", flex: 1,
      renderCell: (params) => (
        <div>
          {params?.value || '-'}
        </div>
      )
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <div className="flex flex-col gap-1">
          {moment(params.value).format('ddd MMM DD YYYY - HH:mm A')}
          <span className="text-theme1 text-xs">{params?.row?.transactionRef}</span>
        </div>
      ),
    },
    { field: "reason", headerName: "Transaction Type", flex: 1,
      renderCell: (params) => (
        <div className="flex flex-col">
          {getReasonName(params?.value)} 
          <span className="text-theme1 text-xs">{params?.row?.paymentMode !== 'Wallet' ? params?.row?.paymentMode : ''}</span>
        </div>
      )
    },
    { field: "amount", headerName: "Amount (NGN)", flex: 1,
      renderCell: (params) => (
        <div className="flex flex-col justify-between gap-x-2 flex-wrap">
          <div className={`${params?.row?.isCredit ? "text-green-500" : "text-red-500"}`}>
            {params?.row?.isCredit ? "+" : "-"}{formatNumber((params.value || 0)?.toFixed(2))}
          </div>
        </div>
      )
     },
    { field: "walletBalance", headerName: "Remaining Balance (NGN)", flex: 1,
      renderCell: (params) => (
        formatNumber((params.value || 0)?.toFixed(2))
      )
    },
    // {
    //   field: "isCredit",
    //   headerName: "Action",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div className={`${params.value ? "success" : "error"}`}>
    //       {params.value ? "Credit" : "Debit"}
    //     </div>
    //   ),
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div className={`${alertType[params.value]}`}>{params.value}</div>
      ),
    },
    {field: 'remarks', headerName: 'Remark'},
  ];

  const [balanceData, setBalanceData] = useState({ balance: 0 });
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    range: "week",
    date: new Date().toLocaleDateString(),
  });
  const [vAccount,setVAccount] = useState();
  const [vAccounts,setVAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTransactions = useCallback(loadTransaction, [
    dateFilter?.date,
    dateFilter?.range,
    selectedAgent,
  ]);

  useEffect(() => {
    getTransactions();
    load();
  }, [getTransactions,selectedAgent]);

  async function loadTransaction() {
    let range = undefined;
    // const date =
    //   dateFilter?.range !== "All"
    //     ? moment(dateFilter?.date)
    //         .subtract(1, dateFilter?.range)
    //         .format("MM/D/YYYY")
    //     : undefined;
    // if (date) {
    //   range = date + "," + dateFilter?.date;
    // }
    setLoading(true);
    const transactions = await getWalletTransactions(range,selectedAgent?._id);
    setLoading(false);
    if (transactions.return) {
      const data = transactions.data?.data?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      let balance = 0;
      const updatedTransactions = data.map((transaction) => {
        if(transaction.status === 'Success') {
          if (transaction.isCredit) {
            balance += transaction.amount;
          } else {
            balance -= transaction.amount;
          }
        }
        
        return {
          ...transaction,
          walletBalance: balance,
        };
      })?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setWalletTransactions(updatedTransactions);
    }
  }

  async function load() {
    const res = await getWallet();
    if (res.return) {
      setBalanceData(res?.data);
    }
  }

  function calculateWallet() {
    return (walletTransactions||[])
      ?.map(obj => ({amount: obj?.amount,isCredit: obj?.isCredit}))
      .reduce((balance, transaction) => {
        return transaction.isCredit ? balance + transaction.amount : balance - transaction.amount;
      }, 0);
  }


  return (
    <div className="flex flex-col gap-4 !text-primary/60 ">
      {/* <div className="flex justify-between items-center gap-4 flex-wrap">
        <div></div>
        <div className="w-full sm:w-auto flex justify-end">
          <div className="flex flex-wrap gap-5 items-center">
            <FilterCalendar
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
            />
            {getTestLevel() < getTestLevel('qa') ? 
              <FileDownloadOutlined color="primary" className="cursor-pointer" />
            :null}
          </div>
        </div>
      </div> */}
      <div className="flex flex-col gap-3 mb-4 w-full">
        {/* {getTestLevel() < getTestLevel('live') ? 
          <div className="tooltip">
            In test mode your balance is unlimited. It is topped-off
            automatically as you spend it.
          </div>
        :null} */}
        {balanceData?.lowBalanceThreshold > balanceData.balance && (
          <div className="tooltip error">
            You are running low on funds. To avoid service disruption, please
            top-up.
          </div>
        )}
      </div>

      <div className="flex justify-between gap-4">
        <h5>My Wallet</h5>
        <div className="flex gap-4 justify-end">
          {/* <div>
            {!vAccounts?.length ? 
              <CreateVirtualAccount reload={() => {loadTransaction();load()}} />
              :null}
          </div> */}
          <div className="inline-block self-start">
            <TopUpBalance accounts={vAccount} reload={() => {loadTransaction();load()}} />
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-wrap">
        <div className="flex-wrap flex gap-4 w-full justify-between">
          <div className="flex gap-2 items-center self-start">
            <FetchUsersInput from={agents} loading={loadings?.agent} 
              onChange={(agent) => setSelectedAgent(agent)} label='Agents'
              disableClearable={false}
            />
            {selectedAgent?._id ? 
              <Icon icon='mdi:clear-circle-outline' className='cursor-pointer'
                onClick={() => setSelectedAgent(null)} 
              />
            :null}
          </div>
          {/* <VirtualAccountDisplay callback={(vAccounts,vAccount) => {
            setVAccounts(vAccounts||[],vAccount);
            setVAccount(vAccount);
          }} /> */}
          <div className="flex-1 flex justify-end">
            <div className="card bg-primary/10 p-4 rounded-lg text-right min-w-[340px]">
              <div>
                Current Balance <span className="text-primary/50">(NGN)</span>
              </div>
              <h4 className="">{formatNumber((balanceData?.balance||0)?.toFixed(2))}</h4>
              <SetupThreshold reload={() => {loadTransaction();load()}} />

            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h5>Transactions</h5>
        <CustomTable
          rows={walletTransactions}
          columns={columns}
          loading={loading}
        />
      </div>
    </div>
  );
}

function VirtualAccountDisplay({callback}) {
  const [data,setData] = useState(
    // [
    //   {label: 'Bank Name', value: 'resData?.bankName'},
    //   {label: 'Account Number', value: 'resData?.accountNumber'},
    //   {label: 'Account Name', value: 'resData?.accountName'},    
    // ]
  )

  useEffect(() => {
    load();
  },[])  
  
  async function load() {
    const res = await getVirtualAccounts();
    if(res.return) {
      const resData = res?.data?.data?.at(0);
      let templated = null;
      if(resData)
        templated = ([
          {label: 'Bank Name', value: resData?.bankName},
          {label: 'Account Number', value: resData?.accountNumber},
          {label: 'Account Name', value: resData?.accountName},    
        ])
      callback && callback(res?.data?.data,templated||data)
    }
  }
  
  return data && (
    <div className="py-4 bg-primary/80 text-secondary flex flex-row gap-2 items-center rounded-md flex-wrap">
      {data?.map((obj,i) => (
        <div key={i} className={`flex flex-1 flex-col gap-2 border-r border-secondary px-4`}>
          {obj?.label}
          <div>
            {obj?.value}
          </div>
        </div>
      ))}
      <div className="p-4">
        <Icon icon='mdi:info' />
      </div>
    </div>
  )
}

function CreateVirtualAccount({hideTip}) {
  const [open,setOpen] = useState(false);
  
  return (
    <div>
      <Button1 onClick={() => setOpen(true)} tooltip={!hideTip &&
        <div>
          You can instantly top-up your Intraverse wallet by making a transfer to your personal account.
        </div>
      }>Create Virtual Account</Button1>
      <Modal1 open={open} setOpen={() => setOpen(false)}>
        <div className="p-10">
          <CreateVirtualAccountForm />
        </div>
      </Modal1>
    </div>
  )
}

function TopUpBalance({ reload, accounts }) {
  const [open, setOpen] = useState(false);
  const [openMethod, setOpenMethod] = useState(false);
  const [data, setData] = useState({ amount: undefined });
  const { user } = useSelector((state) => state.user.userData);

  const handleTopUp = async (reference) => {
    await topUpWalletUsingModal(reference);
    setOpen(false);
    reload && reload();
  };

  return (
    <div>
      <Button1 className='!bg-primary !text-secondary' onClick={() => setOpenMethod(true)}>Top-up Balance</Button1>
      <Modal1 open={openMethod} setOpen={setOpenMethod}>
        <div className="p-10 flex flex-col gap-6 max-w-[600px]">
          <div className="text-center">
            <h5>Select payment method</h5>
          </div>
          <div className="text-center border-b pb-4">
            <p className="text-sm">A transaction fee will apply when you deposit funds into your wallet.</p>
            <p className="text-sm">Please review the fee details before proceeding.</p>
          </div>
          {accounts?.length ? 
            <div className="flex flex-col border text-primary">
              <div className="text-center p-2 border-b">
                Top up via your dedicated virtual account
              </div>
              <div className="py-4 flex flex-row gap-2 items-center rounded-md flex-wrap">
                {accounts?.map((obj,i) => (
                  <div key={i} className={`flex flex-1 flex-col gap-2 border-r border-secondary px-4`}>
                    {obj?.label}
                    <div>
                      {obj?.value}
                    </div>
                  </div>
                ))}
                <div className={`flex flex-1 flex-col gap-2 border-r border-secondary px-4`}>
                  Fee
                  <div>
                    {formatMoney(500)}
                  </div>
                </div>
              </div>
            </div>
          :
            <div className="text-center text-primary flex flex-col gap-2">
              You dont have a dedicated virtual account
              <CreateVirtualAccount hideTip reload={() => {reload()}} />
            </div>
          }
          <div className="text-center -my-4">
            Or
          </div>
          <div>
            <Button1 className='!bg-primary !text-secondary' onClick={() => {setOpen(true); setOpenMethod(false)}}>Top up using Debit / Credit Card</Button1>
          </div>
        </div>
      </Modal1>
      <Modal1 open={open} setOpen={setOpen}>
        <div className="p-10 flex flex-col gap-6 max-w-[800px]">
          <div className="flex flex-col gap-2">
            <h5>Top-up balance by card</h5>
            <p>A transaction fee will apply when you deposit funds into your wallet. Please review the fee details before proceeding.</p>
            <p>Fee: {formatMoney(2000)}</p>
          </div>
          <TextInput
            label="Amount"
            type="number"
            value={data.amount}
            onChange={(ev) => setData({ ...data, amount: ev.target.value })}
            InputProps={{
              endAdornment: "NGN",
            }}
          />
        </div>
        <div className="flex gap-2 p-10 pt-0 flex-wrap-reverse">
          <Button1 className="btn-theme-light !w-auto" onClick={() => setOpen(false)}>
            Cancel
          </Button1>
          <div className="flex-1">
            <PaystackButton
              config={{
                amount: data.amount * 100,
                reference: generateRef("WTR-"),
                email: user?.email,
              }}
              onSuccess={(reference) => {
                handleTopUp(reference?.reference);
              }}
            >
              <Button1 className={"whitespace-nowrap"}>Save Changes</Button1>
            </PaystackButton>
          </div>
        </div>
      </Modal1>
    </div>
  );
}

function SetupThreshold({ reload }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit(ev) {
    ev.preventDefault();

    setLoading(true);
    const res = await setLowBalanceThreshold({ amount: Number(amount?.replaceAll(',','')) });
    if (res.return) {
      enqueueSnackbar("Successful", { variant: "success" });
      setOpen(false);
      reload && reload();
    } else enqueueSnackbar(res.msg, { variant: "error" });
    setLoading(false);
  }
  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="!w-full sm:!w-auto !shadow-none !text-none text-theme1 mt-2"
      >
        Set low balance alert
      </button>
      <Modal1 open={open} setOpen={setOpen}>
        <form
          onSubmit={handleSubmit}
          className="p-4 flex flex-col gap-6 max-w-[800px] text-left"
        >
          <h4>Set-Up low balance threshold</h4>
          <TextInput
            label="Low balance threshold (ngn)"
            value={amount}
            onChange={(ev) => setAmount(ev.target.value)}
            tooltip="When your Intraverse balance reaches this value or below, all administrators in your organization will receive a low balance email. You should configure a threshold that will allow you to top up your balance on time."
            InputProps={{
              endAdornment: "NGN",
            }}
          />
          <div className="flex gap-2 p-4">
            <Button1 className="btn-theme-light" onClick={() => setOpen(false)}>
              Cancel
            </Button1>
            <Button1 loading={loading} type="submit">
              Save Changes
            </Button1>
          </div>
        </form>
      </Modal1>
    </div>
  );
}
