import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack';
import Button1 from '../../form/Button1';
import manualRefund from '../../../controllers/booking/postTicketing/refund/manualRefund';

export default function ManualRefund({data,callback}) {
    const [loadings,setLoadings] = useState({submit: false})
    
    async function handleManual() {
        setLoadings({...loadings,submit: true})
        const res = await manualRefund(data?._id);
        setLoadings({...loadings,submit: false})
        if(res.return) {
          enqueueSnackbar('Refund Complete.',{variant: 'success'})
          callback && callback()
        } else enqueueSnackbar(res.msg,{variant: 'error'})
      }
    
  return (
    <div className='flex flex-col gap-6'>
        <div>
            <h5>Are you sure you want to refund for this ticket?</h5>
            <p>Please review the request and confirm they are accurate.</p>
        </div>
        <div className='flex gap-4'>
        <div>
            <Button1 variant='outlined'>Cancel</Button1>
        </div>
        <Button1 loading={loadings.submit} onClick={handleManual}>Refund</Button1>
        </div>
    </div>
  )
}
