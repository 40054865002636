import { useState } from "react";
import createVirtualAccount from "../../controllers/virtualAccount/createVirtualAccount";
import { enqueueSnackbar } from "notistack";
import TextInput from "../form/TextInput";
import Button1 from "../form/Button1";
import { Checkbox, MenuItem } from "@mui/material";

export default function CreateVirtualAccountForm({callback}) {
    const [data,setData] = useState({
        prefferedBank: '',
    })
    const [loading,setLoading] = useState(false);
    const [accepted,setAccepted] = useState(false);
    
    async function handleSubmit() {
        const payload = {...data}
        setLoading(true);
        const res = await createVirtualAccount(payload);
        setLoading(false);
        if(res.return) {
            enqueueSnackbar(res.msg,{variant: 'success'})
            callback && callback();
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    
    return (
        <div className='flex flex-col gap-4 sm:min-w-[400px] max-w-[500px]'>
            <h5>Create a virtual account</h5>
            <TextInput select label={'Preferred Bank'}
                value={data?.prefferedBank}
                onChange={(ev) => setData({...data,prefferedBank: ev.target.value})}
            >
                <MenuItem value='titan-paystack'>Titan</MenuItem>
                <MenuItem value='wema-bank'>Wema</MenuItem>
            </TextInput>
            <label className="flex gap-2 cursor-pointer">
                <Checkbox checked={accepted} onClick={(ev) => setAccepted(ev.target.checked)} />
                <p>
                    By checking this box I consent to the creation of an account in my name.
                </p>
            </label>
            <div className='flex gap-4 justify-start'>
                <div>
                    <Button1 disabled={!accepted} loading={loading} onClick={handleSubmit}>Submit</Button1>
                </div>
            </div>
        </div>
    )
}