import React, { useState, useEffect } from 'react';
import SupplierSection from '../../../components/Settings/suppliers/SupplierSections';
import getSuppliersName from '../../../controllers/settings/supplier/getFlightSetting';
import getSuppliersList from '../../../controllers/settings/supplier/getSuppliersList';

const Supplier = () => {
  const [loading, setLoading] = useState(true);
  const [flightProviders, setFlightProviders] = useState([]);

  const hotelProviders = [
    { title: 'Suppliers', providers: [{ initials: 'HB', name: 'Hotelbeds', toggle: false }, { initials: 'SA', name: 'Sabre' }] }
  ];

  const toursProviders = [
    { title: 'Suppliers', providers: [{ initials: 'HB', name: 'Hotelbeds', toggle: false }, { initials: 'SA', name: 'Sabre' }] }
  ];

  const getSuppliers = async () => {
    try {
      setLoading(true); // Set loading to true before fetching
      const res = await getSuppliersName();
      const res2 = await getSuppliersList();
      setFlightProviders({...res.data,list: res2?.data});
    } catch (error) {
      console.error("Error fetching suppliers", error);
    } finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const allSuppliers = flightProviders?.list?.map((obj) => ({
    code: obj?.value,
    name: obj?.name,
    icon: obj?.icon,
    enabled: flightProviders?.enabledSuppliers?.includes(obj?.value),
    autoTicketing: flightProviders?.autoTicketingEnabledSuppliers?.includes(obj?.value),
    expiration: flightProviders?.bookingExpiration[obj?.value] || null,
  }));

  const localFlights = flightProviders?.list
    ?.filter((obj) => obj?.value.startsWith('Intra1'))
    ?.map((obj) => ({
      code: obj?.value,
      name: obj?.name,
      icon: obj?.icon,
      enabled: flightProviders?.enabledSuppliers?.includes(obj?.value),
      autoTicketing: flightProviders?.autoTicketingEnabledSuppliers?.includes(obj?.value),
      expiration: flightProviders?.bookingExpiration[obj?.value] || null,
    }));

    console.log(allSuppliers)

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-6">Flight</h2>
      {/* Pass the loading state as well */}
      <SupplierSection allSuppliers={allSuppliers} localFlights={localFlights} loading={loading} 
        settings={flightProviders}
        reload={() => getSuppliers()}
      />

      {/* <hr className="hidden sm:block my-5" />
      <h2 className="text-xl font-bold mb-6">Hotels</h2>
   
      <SupplierSection allSuppliers={allSuppliers} loading={loading} /> */}
    </div>
  );
};

export default Supplier;
