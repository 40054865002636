import moment from 'moment'
import React, { useState } from 'react'
import manualChange from '../../../controllers/booking/postTicketing/change/manualChange';
import { enqueueSnackbar } from 'notistack';
import Button1 from '../../form/Button1';

export default function ManualChange({data,callback}) {
    const [loadings,setLoadings] = useState({submit: false})
    
    async function handleManual() {
        const payload = {
          "changeOrderId": data?._id,
          "flights": undefined,
          "destinations": [
              {
                  "id": ""
              }
          ],
          "travelers": [
              {
                  "id": ""
              }
          ],
        //   "pnr": newPNR || undefined
        }
        if(data?.newSegment?.length) {
          payload.flights = data?.newSegment?.map(obj => (
            {
                "id": obj?._id,
                "departureDate": moment(`${obj?.departure?.date} ${obj?.departure?.time}`,'MMM DD YYYY hh:mm ')?.toString()
            }
          ))
          payload.destinations = data?.newSegment?.map(obj => (
            {
                "id": obj?._id,
                "departureDate": moment(`${obj?.departure?.date} ${obj?.departure?.time}`,'MMM DD YYYY hh:mm ')?.toString()
            }
          ))
        }
        if(data?.passengerInfo?.length) {
          payload.travelers = data?.passengerInfo?.map(({_id,...obj}) => obj)
        }
    
        setLoadings({...loadings,submit: true})
        const res = await manualChange(payload);
        setLoadings({...loadings,submit: false})
        if(res.return) {
          enqueueSnackbar('Update Succesfull.',{variant: 'success'})
          callback && callback()
        } else enqueueSnackbar(res.msg,{variant: 'error'})
      }
    
  return (
    <div className='flex flex-col gap-6'>
        <div>
            <h5>Are you sure you want to confirm this changes?</h5>
            <p>Please review the change request and confirm they are accurate.</p>
        </div>
        <div className='flex gap-4'>
        <div>
            <Button1 variant='outlined'>Cancel</Button1>
        </div>
        <Button1 loading={loadings.submit} onClick={handleManual}>Update</Button1>
        </div>
    </div>
  )
}
