export default function getReasonName(val) {
    switch(val) {
        case 'Wallet':
            return 'Topup';
        case 'IssueTicket':
            return 'Ticket Issuance';
        case "FlightCommission":
            return  "Flight Commission";
        case "PackagePayment":
            return  "Package Payment";
        case "PackagePaymentCustomer":
            return  "Package Customer Payment";
        case "HotelPayment":
            return  "Hotel Payment";
        case "HotelPaymentCustomer":
            return  "Hotel Customer Payment";
        case "InsurancePayment":
            return  "Insurance Payment";
        case "InsurancePaymentCustomer":
            return  "Insurance Customer Payment";
        case "HotelCommission":
            return  "Hotel Commission";
        case "TourCommission":
            return  "Tour Commission";
        case "WalletPayout":
            return  "Wallet Payout";
        case "AddCard":
            return  "Add Card";
        case "UpdateFlightBooking":
            return  "Update Flight Booking";
        case "Refund":
            return  "Refund";
        case "Cart":
            return  "Cart Payment";
        case "AgentCustomerTicketPayment":
            return  "Agent Customer Ticket Payment";
        case "VirtualAccount":
            return  "Virtual Account";
        case "ChangeOrderRequest":
            return  "Change Order Request";
        default: return val;
    }
  }
  