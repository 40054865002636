import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button1 from '../../form/Button1'
import TextInput from '../../form/TextInput'
import { CircularProgress, MenuItem } from '@mui/material'
import getAdminTeamMembers from '../../../controllers/settings/adminTeam/getTeamMembers'
import refundTicketRequest from '../../../controllers/booking/postTicketing/refund/refundTicketRequest'
import { enqueueSnackbar } from 'notistack'
import createTask from '../../../controllers/booking/assignTask/createTask'
import { taskData } from '../../../data/task/taskData'
import Collapse from '../../mini/Collapse'
import CalendarInput1 from '../../form/CalendarInput1'


export default function AssignRefund({data,callback}) {
  const [handleOption,setHandleOption] = useState('direct')
  const [details,setDetails] = useState('')
  const [staff,setStaff] = useState('')
  const [staffs,setStaffs] = useState([])
  const [loadings,setLoadings] = useState({fetchStaff: false,submit: false})
  const [assignData,setAssignData] = useState({
    "dueDate": undefined,
    "priority": 'Medium',
  })


  useEffect(() => {
    if(data)
      loadStaff()
  },[data])

  async function loadStaff() {
    setLoadings({...(loadings||{}),fetchStaff: true})
    const res = await getAdminTeamMembers();
    setLoadings({...(loadings||{}),fetchStaff: false})
    if(res.return) {
      setStaffs(res?.data?.data||[])
    }
  }

  async function handleRefund() {
    setLoadings({...loadings,submit: true})
    const res = await refundTicketRequest(data?._id);
    setLoadings({...loadings,submit: false})
    if(res?.return) {
        enqueueSnackbar('Ticket has been Refunded.',{variant: 'success'})
        callback && callback()
    } else enqueueSnackbar(res?.msg||'Something went wrong',{variant: 'error'})
  }

  async function handleAssign() {
    if(!staff)
      return enqueueSnackbar('Please select a staff to assign to!',{variant: 'error'});

    const payload = {...taskData,
      taskType: 'Refund',
      assignee: staff,
      note: details,
      "refundTicketManagementId": data?._id,
      ...(assignData||{}),
    }

    setLoadings({...loadings,submit: true})
    const res = await createTask(payload);
    setLoadings({...loadings,submit: false})
    if(res.return) {
        enqueueSnackbar('Ticket has been requested for change.',{variant: 'success'})
        callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }


  async function handleSubmit() {
    if(handleOption === 'direct')
      handleRefund();
    else if(handleOption === 'assign')
      handleAssign();
  }

  
  return (
    <div className='flex flex-col gap-4'>
      <div>
        <div className='flex gap-1'>
          <p>Order Number: </p><span>{data?.orderId}</span>
        </div>
        <div className='flex gap-1'>
          <p>Refund Request Time: </p><span>{data?.updatedAt ? moment(data?.updatedAt)?.format('YYYY-MM-DD, HH:mm') : ''}</span>
        </div>
      </div>
      <div className='flex justify-end'>
        <div>
          <Button1 variant='text'>Check fare rules</Button1>
        </div>
      </div>

      <div>
        <TextInput label='' select value={handleOption} onChange={(ev) => setHandleOption(ev.target.value)}>
          <MenuItem value={'direct'}>Handle By Supplier</MenuItem>
          <MenuItem value='assign' >Assign To a Team</MenuItem>
        </TextInput>
      </div>

      <TextInput label='Request Details' multiline rows={4} value={details} onChange={(ev) => setDetails(ev.target.value)} />
      
      {handleOption === 'assign' ? 
        <TextInput label='Select Staff' select
          value={staff}
          onChange={(ev) => setStaff(ev.target.value)}
          InputProps={{
            startAdornment: loadings?.fetchStaff ? <CircularProgress className='!w-4 !h-4' /> : null
          }}
        >
          {staffs?.map((obj,i) => (
            <MenuItem key={i} value={obj?._id || obj?.id}>{obj?.firstName} {obj?.lastName}</MenuItem>
          ))}
        </TextInput>
      :null}

      {handleOption === 'assign' ? 
        <Collapse label={<div className='text-end w-full'>More</div>}>
            <div className='flex gap-4'>
              <div>
                <TextInput label='Priority' select
                  value={assignData?.priority}
                  onChange={(ev) => setAssignData({...assignData,priority: ev.target.value})}
                >
                  {['Low', 'Medium', 'High', 'Critical']?.map((val,i) => (
                    <MenuItem key={i} value={val} >{val}</MenuItem>
                  ))}
                </TextInput>
              </div>
              <CalendarInput1 label='Due Date' className='py-2 !pt-0'
                value={assignData?.dueDate}
                onChange={(val) => setAssignData({...assignData,dueDate: moment(val?.start || val).format('YYYY-MM-DD')})}
                />
            </div>
        </Collapse>
      :null}

      <div>
        <Button1 loading={loadings?.submit} onClick={handleSubmit}>{handleOption === 'assign' ? 'Assign Request' : 'Send To Supplier'}</Button1>
      </div>
    </div>
  )
}
